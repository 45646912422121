import { useEffect, useState } from "react";

export const useHours = (date) => {

  const selectedDate = new Date(date + "T00:00:00");
  const dayGet = selectedDate.getDay();
  const [hoursObject, setHoursObject] = useState('');

  useEffect(() => {
    if (date !== '') {
      if (dayGet === 6) {
        setHoursObject([
          {
            hour: "07:00",
            index: 1
          },
          {
            hour: "07:50",
            index: 2
          },
          {
            hour: "08:40",
            index: 3
          },
          {
            hour: "09:30",
            index: 4
          },
          {
            hour: "10:20",
            index: 5
          },
          {
            hour: "11:10",
            index: 6
          },
          {
            hour: "12:00",
            index: 7
          },
          {
            hour: "12:50",
            index: 8
          },
          {
            hour: "13:40",
            index: 9
          },
          {
            hour: "14:30",
            index: 10
          },
          {
            hour: "15:20",
            index: 11
          },
          {
            hour: "16:10",
            index: 12
          },
          {
            hour: "17:00",
            index: 13
          },
          {
            hour: "17:50",
            index: 14
          },
          {
            hour: "18:40",
            index: 15
          }
        ])
      } else {
        setHoursObject([
          {
            hour: "08:20",
            index: 1
          },
          {
            hour: "09:10",
            index: 2
          },
          {
            hour: "10:00",
            index: 3
          },
          {
            hour: "10:50",
            index: 4
          },
          {
            hour: "11:40",
            index: 5
          },
          {
            hour: "12:30",
            index: 6
          },
          {
            hour: "13:20",
            index: 7
          },
          {
            hour: "14:10",
            index: 8
          },
          {
            hour: "15:00",
            index: 9
          },
          {
            hour: "15:50",
            index: 10
          },
          {
            hour: "16:40",
            index: 11
          },
          {
            hour: "17:30",
            index: 12
          },
          {
            hour: "18:20",
            index: 13
          },
          {
            hour: "19:10",
            index: 14
          },
          {
            hour: "20:00",
            index: 15
          }
        ])
      }
    }

  }, [date, dayGet])

  return { hoursObject }
}