import './NotFound.css'

const NotFound = () => {
  return (
    <div className='not_found_container'>
        <h1>404</h1>
    </div>
  )
}

export default NotFound