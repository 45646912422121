import { useState, useEffect, useReducer } from 'react';
import { db } from '../firebase/config';
import { collection, addDoc, Timestamp, doc, setDoc } from 'firebase/firestore';

const initialState = {
    loading: null,
    error: null
}

const insertReducer = (state, action) => {
    switch (action.type) {
        case "LOADING":
          return { loading: true, error: null };
        case "INSERTED_DOC":
          return { loading: false, error: null };
        case "ERROR":
          return { loading: false, error: action.payload };
        default:
          return state;
        }

}

export const useInsertDocument = (docCollection) => {

    const [response, dispatch] = useReducer(insertReducer, initialState);
    const [error, setError] = useState(false)

    // deal with memory leak
    const [cancelled, setCancelled] = useState(false);

    const checkCancelBeforeDispatch = (action) => {
        if (!cancelled) {
            dispatch(action);
        };
    };

    const insertDocument = async (document) => {

        checkCancelBeforeDispatch({
            type: "LOADING",
        })

        try {
            const newDocument = { ...document, createdAt: Timestamp.now() }

            const insertedDocument = await addDoc(
                collection(db, docCollection),
                newDocument
            )

            checkCancelBeforeDispatch({
                type: "",
                payload: insertedDocument
            })

        } catch (error) {

            console.log(error);

            checkCancelBeforeDispatch({
                type: "ERROR",
                payload: error.message
            })

        }

    }
    const setDocument = async (document, id) => {

        checkCancelBeforeDispatch({
            type: "LOADING",
        })

        try {
            const newDocument = { ...document, createdAt: Timestamp.now() }

            const settedDocument = await setDoc(doc(db, docCollection, id), newDocument)

            checkCancelBeforeDispatch({
                type: "",
                payload: settedDocument
            })

        } catch (error) {

            setError(true)

            checkCancelBeforeDispatch({
                type: "ERROR",
                payload: error.message
            })

        }

    }

    useEffect(() => {
        return () => setCancelled(true);
    }, []);

    return { insertDocument, setDocument, response, error }
}