// CSS
import './Home.css'

// Components
import ScheduleForm from '../../components/ScheduleForm/ScheduleForm'

// React
import { useState, useEffect } from 'react'

// Functions
// import { addDataFirestore } from '../../firebase/post'
import AlertMessage from '../../components/AlertMessage/AlertMessage'
import { useAuthValue } from '../../context/AuthContext'
import { useInsertDocument } from '../../hooks/useInsertDocument'
import { useFetchDocuments } from '../../hooks/useFetchDocuments';
import { useFetchEnabledHours } from '../../hooks/useFetchEnabledHours';
import { useHours } from '../../hooks/useHours'
import { useAdminValue } from '../../context/AdminContext'
import { useFetchDayOff } from '../../hooks/useFetchDayOff'

const uuid = require('uuid');

// const hours = ["07:10", "08:00", "08:50", "09:40", "10:30", "11:20", "12:10", "13:00", "13:50", "14:40", "15:30", "16:20", "17:10", "18:00", "18:50", "19:40", "20:30"]
const barbers = ["Carlos", "Donizete"]

export default function App() {
    // States
    const [name, setName] = useState("");
    const [cel, setCel] = useState("");
    const [date, setDate] = useState("");
    // const [scheduleDate, setScheduleDate] = useState("");
    const [hour, setHour] = useState([]);
    const [service, setService] = useState("");
    const [professional, setProfessional] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [appointmentHours, setAppointmentHours] = useState("");
    const [formError, setFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [hoursIndex, setIndex] = useState();
    const [noDayOffBarber, setNoDayOffBarber] = useState();
    const [exclusiveHours, setExclusiveHours] = useState();
    const { isAdmin } = useAdminValue();
    // const [hours, setHours] = useState(undefined)
    const { hoursObject } = useHours(date);

    // Custom Hooks

    const handleRedirect = (hour, professional, service, transactionId) => {
        window.location.href = `https://wa.me/19999787030?text=Olá, sou o ${name.toUpperCase()} e confirmo meu agendamento com os seguintes dados:%0A%0AData: *${new Date(date + 'T00:00:00').toLocaleDateString()}*%0AHorário: *${hour}*%0ABarbeiro: *${professional}*%0AServiço: *${service}*%0AID do agendamento: *${transactionId}*%0ACaso seja necessário cancelar seu agendamento, utilize o link abaixo: %0Ahttps://salaolima.com/cancel-appointment/${transactionId}%0A%0A_ENVIE ESSA MENSAGEM PARA CONFIRMAR SEU AGENDAMENTO_`
    };

    const { user: authUser } = useAuthValue();

    const { setDocument, error: insertError } = useInsertDocument('transactions');
    const {
        documents,
        loading: loadingUserData,
        error
    } = useFetchDocuments("users", authUser ? authUser.uid : null);
    const {
        documents: enabledHours,
        // error: errorEnabledHours
    } = useFetchEnabledHours("transactions", professional, date);
    // const {
    //     documents: dayOffs,
    // } = useFetchDocuments("day-off", null, false, false);
    const {
        documents: dayOffs,
        // loading: dayOffLoading
    } = useFetchDayOff("day-off", date);

    const uid = authUser ? authUser.uid : null;

    const handleDateChange = (e) => {
        const chosenDate = new Date(e.target.value + 'T00:00:00');
        const chosenDateISO = chosenDate.toISOString().split('T')[0]

        const checkSunday = chosenDate.getDay();

        if (checkSunday === 0) {
            setFormError(true);
            setFormErrorMessage("Não atendemos aos domingos. Escolha outra data.")
            setDate('');
        } else {
            setDate(chosenDateISO);
        }

    }

    useEffect(() => {

        if (dayOffs && dayOffs.length > 0) {
            const barber = dayOffs[0].professional

            const working = barbers.filter((e) => !barber.includes(e))

            setNoDayOffBarber(working)

            setFormError(true);
            setFormErrorMessage(`O Barbeiro ${barber} estará de folga no dia ${new Date(dayOffs[0].id + 'T00:00:00').toLocaleDateString()}`)
        } else {
            setNoDayOffBarber(barbers)
        }

        // setNoDayOffBarber(barbers)
        setService('')
        setProfessional('')
        setHour('')
    }, [dayOffs])

    const handleHourChange = (e) => {

        const hours = hoursObject.map(ele => ele.hour)

        if (service === "Cabelo e Barba") {

            const hoursIndex = hours.indexOf(e.target.value) + 1;

            if (appointmentHours.includes(hours[hoursIndex])) {
                setHour([0])
                setFormError(true);
                setFormErrorMessage("Horário seguinte indisponível. Este serviço consome 1h 40min.");
                return;
            } else {
                setHour(() => [
                    e.target.value,
                    hours[hoursIndex]
                ]);
                setIndex(hoursIndex);
            }

        } else if (service === "Prótese + Corte") {

            const hoursIndex = [hours.indexOf(e.target.value) + 1, hours.indexOf(e.target.value) + 2]


            if (appointmentHours.includes(hours[hoursIndex[0]]) || appointmentHours.includes(hours[hoursIndex[1]])) {
                setHour([0])
                setFormError(true);
                setFormErrorMessage("Horário seguinte indisponível. Este serviço consome 2h 30min.");
                return;
            } else {
                setHour(() => [
                    e.target.value,
                    hours[hoursIndex[0]],
                    hours[hoursIndex[1]]
                ]);
                setIndex(hoursIndex[0]);
            }

        } else if (service === "Manutenção da Prótese") {

            const hoursIndex = hours.indexOf(e.target.value) + 1;

            if (appointmentHours.includes(hours[hoursIndex])) {
                setHour([0])
                setFormError(true);
                setFormErrorMessage("Horário seguinte indisponível. Este serviço consome 1h 40min.");
                return;
            } else {
                setHour(() => [
                    e.target.value,
                    hours[hoursIndex]
                ]);
                setIndex(hoursIndex[0]);
            };

        } else {
            const hoursIndex = hours.indexOf(e.target.value) + 1;

            setHour(() => [e.target.value]);
            setIndex(hoursIndex);
        }

    }

    const handleCelChange = (e) => {

        const target = e.target.value.replace(/\(|\)|-| /g, '');

        if (target.match(/[a-zA-Z]/)) {
            return;
        }
        if (target.length < 12) {
            setCel(e.target.value);
        } else {
            return;
        }

        let celArray = target.split('');

        if (e.target.value.length === 11) {
            celArray.splice(0, 0, '(')
            celArray.splice(3, 0, ')')
            celArray.splice(4, 0, ' ')
            celArray.splice(10, 0, '-')
            let cel = celArray.join('')
            setCel(cel)
        }
    }

    const handleProfessionalChange = (e) => {
        setHour("-")
        setProfessional(e.target.value)
    }

    const handelServiceChange = (e) => {
        setProfessional('');
        setHour('');
        setService(e.target.value);
    }

    // Handle submit form function
    const handleSubmit = (e) => {

        (async () => {
            e.preventDefault();
            if (!navigator.onLine) {
                setFormError(true)
                setFormErrorMessage("Sem Internet! Atualize a página.")
                setAppointmentHours([])
                setExclusiveHours([])
            }
            else {
                const tid = uuid.v4()
                const uid = authUser ? authUser.uid : null
                setLoading(true);

                const appointment = {
                    name,
                    cel,
                    date,
                    hour,
                    tid,
                    professional,
                    service,
                    uid,
                    hours_index: hoursIndex
                }


                setDocument(appointment, tid)

                if (insertError) {
                    window.alert('Recarregue a página')
                } else {

                    if (!navigator.onLine) {
                        setFormError(true)
                        setFormErrorMessage("Sem Internet! Atualize a página.")
                        setAppointmentHours([])
                        setExclusiveHours([])
                    } else {
                        setFormSubmitted(true);
                        setLoading(false);
                        setTransactionId(tid);
                        console.log(appointmentHours)
                        setFormError(true)
                        setFormErrorMessage('Aguarde o redirecionamento para o WhatsApp e envie a mensagem!')
                        setTimeout(() => {
                            handleRedirect(hour[0], professional, service, tid)
                        }, 3000)
                    }

                }
            }
        })()

    };

    // const handleClickButtonForm = (e) => {
    //     if(formSubmitted) {
    //         setTimeout(() => {
    //             e.target.setAttribute('disabled', 'true')
    //         }, 10)
    //     }
    // }

    useEffect(() => {
        localStorage.setItem('transactionId', transactionId)
    }, [transactionId])

    useEffect(() => {
        if (!isAdmin) {
            if (uid !== null && documents !== null) {
                documents.forEach(e => {
                    setName(e.name);
                    setCel(e.cellphone);
                })
            }
        }
    }, [uid, documents, isAdmin]);

    useEffect(() => {
        if (error) {
            setFormError(true)
            setFormErrorMessage("Sem Internet! Atualize a página.")
        }
    }, [error])

    useEffect(() => {
        if (date && professional && enabledHours) {


            const handleEnabledHours = async () => {
                if (!navigator.onLine) {
                    setFormError(true)
                    setFormErrorMessage("Sem Internet! Atualize a página.")
                    setAppointmentHours([])
                    setExclusiveHours([])
                } else {
                    let data = enabledHours;

                    let apt = data
                        .filter(e => e.date === date)
                        .filter(e => e.professional === professional)
                        .map(e => e.hour)

                    let hoursFormatted = JSON.stringify(apt)
                        .replace(/\[|\]/g, '')
                        .replace(/(['"])/g, '')
                        .split(',');


                    if (new Date(date + "T00:00:00").getDay() === 6) {
                        hoursFormatted.push("18:40")
                    } else {
                        hoursFormatted.push("20:00")
                    }

                    setAppointmentHours(hoursFormatted);
                }
            }
            if(!formSubmitted){
                handleEnabledHours();
            }
        }
    }, [date, professional, enabledHours, formSubmitted]);

    useEffect(() => {

        
        if (hoursObject) {
            const hours = hoursObject.map(ele => ele.hour)
            let hoursFiltered = hours.filter(ele => !appointmentHours.includes(ele))

            const dateParsed = new Date(date + 'T00:00:00').toLocaleDateString();

            const dateNow = new Date();

            if (dateNow.toLocaleDateString() === dateParsed) {

                if (!navigator.onLine) {
                    hoursFiltered = [];
                } else {
                    const hourNow = new Date().getTime()

                    hoursFiltered = hoursFiltered.filter((ele) => {
                        let received;

                        const date = new Date()

                        const dateOffset = date.getTimezoneOffset();
                        let dateTimestamp = date.getTime()

                        dateTimestamp = dateTimestamp - dateOffset * 60 * 1000

                        const dateNowJson = new Date(dateTimestamp).toJSON().split("T")[0]
                        const dateHourMounted = new Date(`${dateNowJson}T${ele}:00`).getTime() //- (600000 * 6 * 24); //.358Z

                        if (hourNow <= dateHourMounted - (600000)) { //+(600000 * 6 * 24)
                            received = ele
                        } else {
                            console.log('nao é')
                        }
                        console.log(received)
                        return received;
                    })
                }

                if (hoursFiltered.length === 0) {
                    setFormError(true)
                    setFormErrorMessage('Não temos horários com este barbeiro.')
                }
                setExclusiveHours(hoursFiltered)
            } else if (!navigator.onLine) {
                setExclusiveHours([]);
            } else {
                if (hoursFiltered.length === 0) {
                    setFormError(true)
                    setFormErrorMessage('Não temos horários com este barbeiro.')
                }
                setExclusiveHours(hoursFiltered);
            }
        }

    }, [appointmentHours, hoursObject, date])

    return (
        <div className="container-home">
            {formError &&
                <AlertMessage
                    setFormError={setFormError}
                    formErrorMessage={formErrorMessage}
                />
            }

            {
                // !formSubmitted &&
                !loadingUserData &&
                !error &&
                <ScheduleForm
                    name={name}
                    cel={cel}
                    date={date}
                    hour={hour}
                    service={service}
                    professional={professional}
                    setName={setName}
                    setCel={setCel}
                    setDate={setDate}
                    setHour={setHour}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    setService={setService}
                    setProfessional={setProfessional}
                    appointmentHours={appointmentHours}
                    hours={hoursObject}
                    setFormErrorMessage={setFormErrorMessage}
                    setFormError={setFormError}
                    user={authUser}
                    index={hoursIndex}
                    dayOffs={dayOffs}
                    noDayOffBarber={noDayOffBarber}
                    setNoDayOffBarber={setNoDayOffBarber}
                    barbers={barbers}
                    exclusiveHours={exclusiveHours}
                    handleDateChange={handleDateChange}
                    handleHourChange={handleHourChange}
                    handleCelChange={handleCelChange}
                    handleProfessionalChange={handleProfessionalChange}
                    handelServiceChange={handelServiceChange}
                    isAdmin={isAdmin}
                    formSubmitted={formSubmitted}
                />
            }
            {error &&
                <p>{error}</p>
            }
            {formSubmitted && transactionId === '' && <h1>Carregando...</h1>}
        </div>

    )
}
